import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import "../styles/conditionsOfUse.scss"


const ConditionsOfUsePage = (props) => (

      <LayoutMain>
        <Helmet>
          <title>Structured Growth Conditions of Use</title>
          <meta
            name="description"
            content="Structured Growth conditions of use"
          />
          <bodyAttributes
            className="theme1"
          />
        </Helmet>


        <div className="container-conditions-use padding-standard bgColorLight">

          <div className="conditionsUse">

            <h1>CONDITIONS OF USE</h1>
            <p>Last Updated: December 18, 2023</p>

            <p>Structured Growth LLC and/or their affiliates ("Structured Growth") provide features and other products and services to you when you visit structured-growth.com (the "Structured Growth Website"), use Structured Growth devices, products, or services, use Structured Growth applications, or use software provided by Structured Growth in connection with any of the foregoing (collectively "Structured Growth Services"). Structured Growth provides the Structured Growth Services to you subject to the conditions set out on this page.</p>


            <h2>PRIVACY</h2>
            <p>Please see our Privacy Notice on the Structured Growth Website to understand how we collect and process your personal information through Structured Growth Services.</p>


            <h2>ELECTRONIC COMMUNICATIONS</h2>
            <p>When you use Structured Growth Services, or send e-mails, text messages, and other communications from your device to us, you may be communicating with us electronically. You consent to receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and messages through Structured Growth Services, and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>


            <h2>DATA RECEPTION AND TRANSMISSION</h2>
            <p>Some Structured Growth services will receive and transmit Structured Growth information that is in proximity to a physical device with Structured Growth services installed.  The physical device will receive data from a Structured Growth device and transmit this data to the Structured Growth servers.  Any encrypted data will pass through without decryption. Structured Growth is not responsible for any costs due to bandwidth or data reception and transmission.</p>


            <h2>COPYRIGHT</h2>
            <p>All content included in or made available through any of the Structured Growth Services, such as text, graphics, logos, and software is the property of Structured Growth or its content suppliers and protected by United States and international copyright laws. The compilation of all content included in or made available through any of the Structured Growth Services is the exclusive property of Structured Growth and protected by U.S. and international copyright laws.</p>


            <h2>TRADEMARKS</h2>
            <p>Graphics, logos, button icons, and scripts, included in or made available through any of the Structured Growth Services are trademarks or trade dress of Structured Growth in the U.S. and other countries. Structured Growth's trademarks and trade dress may not be used in connection with any product or service that is not Structured Growth 's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Structured Growth. All other trademarks not owned by Structured Growth that appear in any of the Structured Growth Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Structured Growth.</p>


            <h2>PATENTS</h2>
            <p>One or more patents owned by Structured Growth apply to the Structured Growth Services and to the features and services accessible via the Structured Growth Services. Portions of the Structured Growth Services operate under license of one or more patents.</p>

            <h2>FEEDBACK</h2>
            <p>You may provide written or verbal feedback, suggestions, comments, or input to us relating to Structured Growth Services, Software Apps, Structured Growth Products, User Devices, or other opportunities for our existing or future activities ("Feedback"). By providing Feedback to us, you grant to us the worldwide, nonexclusive, unrestricted, perpetual, irrevocable (on any basis whatsoever), royalty free right for us to use such Feedback in any way we determine, including through third parties, without any obligation to you for compensation, attribution, accounting or otherwise. You will only provide to us Feedback for which you have the right to grant to us the rights listed in the preceding sentence.</p>

            <p>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</p>
            <p>THE Structured Growth SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Growth SERVICES ARE PROVIDED BY Structured Growth ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. Structured Growth MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE Structured Growth SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Growth SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE Structured Growth SERVICES IS AT YOUR SOLE RISK.</p>

            <p>TO THE FULL EXTENT PERMISSIBLE BY LAW, Structured Growth DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Structured Growth DOES NOT WARRANT THAT THE Structured Growth SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Growth SERVICES, Structured Growth'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM Structured Growth ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, Structured Growth WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY Structured Growth SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY OF THE Structured Growth SERVICES, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</p>


            <h2>DISPUTES</h2>
            <p>Any dispute or claim relating in any way to your use of any of the Structured Growth Services, or to any products or services sold or distributed by Structured Growth will be resolved by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement.</p>

            <p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages) and must follow the terms of these Conditions of Use as a court would.</p>

            <p>The arbitration will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-Related Disputes. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules.</p>

            <p>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>


            <h2>APPLICABLE LAW</h2>
            <p>By using any Structured Growth Services, you agree that the Federal Arbitration Act, applicable federal law, and the laws of the state of California, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and Structured Growth.</p>

            <p>The Structured Growth Services are controlled, operated and administered by Structured Growth from offices within the USA. If you access Structured Growth Services from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the content accessed through the Structured Growth Services in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>


            <h2>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h2>
            <p>Please review our other policies posted on the Structured Growth Website. These policies also govern your use of Structured Growth Services. We reserve the right to make changes to our Structured Growth Website, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>


            <h2>CONTACT INFORMATION</h2>
            <p>Structured Growth LLC<br/>
            4304 Endcliffe Drive<br/>
            Austin, TX 78731</p>

            <p>Email Address: hello@structured-growth.com</p>

          </div>

        </div>


      </LayoutMain>
    )

export default ConditionsOfUsePage



